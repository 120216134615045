@import '../node_modules/reset-css/sass/_reset.scss';

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// * {
//   color: $label-gray-dark;
// }

// #root {
//   background: #282c2e;
//   height: 100vh;
// }

*::-webkit-scrollbar {
  width: 0.8em;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}
*::-webkit-scrollbar-thumb {
  // background-color: rgba(0, 0, 0, 0.1);
  background-color: #3b4145;
  // outline: 1px solid slategrey;
}
