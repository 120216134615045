.login {
  width: 300px;
  margin: 100px auto;
  text-align: center;
  border: 1px solid #000000;
  background: rgba(255, 255, 255, 0.08);
  padding: 30px;
  padding-top: 40px;
  border-radius: 5px;
  height: 290px;

  .login_error {
    color: #de3f33;
    border: 1px solid #f44336;
    background: rgba(123, 123, 123, 0.13);
    margin: 8px;
  }

  button {
    border: 1px solid grey;
    margin: 10px 0px;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    margin-left: -10px;

    .spectrum_icon {
      margin-right: 8px;
    }
    .login_title {
      font-size: 20px;
      margin-top: -3px;
    }
  }

  .login_form_group {
    * {
      color: #6b6b6b;
    }
    > * {
      margin: 8px;
    }
    .MuiInputBase-input {
      color: white !important;
    }

    .MuiInput-underline:before {
      border-bottom: 1px solid #6b6b6b !important;
    }

    .MuiInput-underline:after,
    .MuiInput-underline:hover::before {
      border-bottom: 2px solid #63de00 !important;
    }

    .MuiFormLabel-root.Mui-focused {
      color: #6b6b6b !important;
    }

    .Mui-disabled {
      color: #6b6b6b;
    }
  }
}
