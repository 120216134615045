@import './themes/theme.scss';

// .App {
//   text-align: center;
// }

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }

// .login {
//   width: 300px;
//   margin: 100px auto;
//   text-align: center;

// }

// --------------------------------------------------------------------------------------

html {
  color: $white;
  background: $black;
  overflow-y: scroll;
  height: 100%;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px gray;
    border-radius: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: $button-default-color;
    //border: 1px solid $primary-dark;
    border-radius: 0;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: $primary-dark;
  }

  div,
  p,
  h1,
  h2,
  h3,
  h4 {
    font-family: $system-font;
  }
  a {
    color: $white;
    &:hover {
      color: $highlight;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    font-family: $system-font;
    -webkit-text-fill-color: $white;
    transition: background-color 5000s ease-in-out 0s;
  }

  body {
    height: 100%;
  }

  #root {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

*, *:before, *:after {
  box-sizing: inherit;
}

.App {
  position: relative;
  background-color: $surround-color;
  border-left: solid 10px $surround-color;
  border-right: solid 10px $surround-color;
  min-height: 100vh;
  font-family: $system-font;

  *:focus {
    outline: none;
  }

  .login {
    width: 300px;
    margin: 100px auto;
  }

  // Context menu overrides
  .react-contextmenu {
    box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid $label-gray-dark;
    background-color: $black;
    padding: 0;
    margin: 0;
    text-align: left;
    z-index: 99;

    .menu-group {
      //border-bottom: 1px solid #4e4e4e;

      &:last-child {
        //bottom-border: none;
      }

      .menu-entry {
        .react-contextmenu-item {
          font-size: 13px;
          font-family: Arial, sans-serif;
          line-height: 14px;

          &:last-child {
            border-bottom: 1px solid $border-color;
          }
          .entry-icon,
          .entry-label {
            display: inline-block;
            color: $white;
          }

          .entry-icon {
            height: 30px;
            width: 30px;
            text-align: center;
            float: left;

            svg {
              margin: 7px 5px; // half of line-height
              width: 20px;
              height: 20px;

              path {
                color: $white;
              }
            }
          }

          .entry-label {
            border-left: 1px solid $border-color;
            padding: 10px 12px;
          }

          &:hover {
            background-color: $gray;
            cursor: pointer;
          }

          &:focus {
            border: none;
            outline: none;
          }
        }

        .zoom {
          font-size: 13px;
          font-family: Arial, sans-serif;
          padding: 5px 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .react-contextmenu-item,
          .title,
          .zoom-value {
            color: $black;
          }

          .minus,
          .plus {
            svg {
              width: 15px;
              height: 15px;

              path {
                color: $black;
              }
            }
          }
        }
      }
    }
  }

  // react-grid-layout overrides
  .react-grid-placeholder {
    background-color: #003f00 !important;
    opacity: 0.85 !important;
    -webkit-box-shadow: 0px 0px 4px 0px #0cff00;
    box-shadow: 0px 0px 4px 0px #0cff00;
  }

  user-select: none; // eliminates highlighting, help cursor etc

  .save-button {
    bottom: 15px;
    right: 55px;
    position: fixed;
    z-index: 99;
  }

  .load-button {
    bottom: 15px;
    right: 15px;
    position: fixed;
    z-index: 99;
  }
}

// Select overrides
.basic-single-label {
  //  todo: fix
}
.basic-single {
  background-color: $black;
  margin-bottom: 10px;

  .select__control {
    width: 500px;
    background-color: $black;
    color: $white;
    border-radius: 0 !important;
    height: 100%;
    .select__value-container {
      min-height: 30px;
    }

    &.select__control--is-focused {
      border-color: $highlight;
      box-shadow: 0 0 0 1px $highlight;
    }

    .select__single-value {
      color: $white;
    }

    .select__indicators {
      background-color: $black;
    }
  }

  .select__menu {
    background-color: $black;
    border: 1px solid $white;

    .select__option--is-selected {
      background-color: $white;
      color: $black;
    }

    .select__option--is-focused {
      background-color: $inactive;
      color: $black;
    }
  }

  .react-select-custom-item {
    background-color: $black;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 23px;
    text-align: left;
    border-radius: 0 !important;
    z-index: 9999;
    cursor: pointer;
    margin: 3px auto;
    padding-bottom: 2px;
    padding-top: 2px;

    &:hover {
      outline: 1px solid $white;
    }
  }
}

.hide {
  display: none !important;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

.fab-container {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 10px;

  button {
    margin-left: 10px;
  }
}

.kiosk_modal_button {
  margin: 10px;
  font-size: 12px;
  width: fit-content;
  position: absolute;
  right: 80px;
  cursor: pointer;
}

.logout {
  margin: 10px;
  font-size: 12px;
  text-align: right;
  width: fit-content;
  position: absolute;
  right: 0px;
  a {
    text-decoration: none;
  }
}
.react-resizable-handle::after {
  border-right: 2px solid $highlight !important;
  border-bottom: 2px solid $highlight !important; // Update rgba values for color and transparency.
}

.tooltip-text {
  font-family: $system-font;
  font-size: 18px;
}

// Page header common
.page-header {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 576px) {
    flex-direction: row;
  }

  .page-navigation {
    margin-right: auto;
    margin-bottom: 5px;

    @media screen and (min-width: 576px) {
      margin-bottom: 0;
    }

    a {
      text-decoration: none;
    }
    .logo-container {
      display: flex;
      align-items: center;

      a {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        &:hover {
          color: $white;
        }
      }

      .spectrum-icon {
        margin: 4px 8px;
      }

      .page-name {
        font-size: 30px;
        display: flex;
        align-items: center;
        text-transform: capitalize;
      }

      .admin-indicator {
        margin: 0 15px;
        padding: 2px 3px;
        color: red;
        font-size: 20px;
        -webkit-box-shadow: 0 0 4px 1px red;
        box-shadow: 0 0 4px 1px red;
      }
    }
  }
}

.login-error {
  margin: 10px auto;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: $system-font;
  font-size: 20px;
  color: $warning-text-color;
  min-height: 40px;
}

.login_connection_status {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: $system-font;
  font-size: 14px;

  .disconnected {
    color: $error;
  }
  .connected {
    color: $label-gray;
  }
}

/* lists */
.list {
  margin-bottom: 20px;
  .list-name {
    margin-bottom: 10px;
    //margin-left: 9px;
    text-transform: capitalize;
  }

  .list-item {
    min-height: 30px;
    background-color: $modules-bg-gray;
    margin-bottom: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    padding: 7px 9px;
    &.add-new-screen {
      background-color: #222;
      justify-content: center;
      font-size: 16px;
      svg {
        margin-right: 5px;
        font-size: 16px;
      }
    }
    &.toggles-bar {
      background-color: #222;
      font-size: 16px;
      border-left: 0;
      justify-content: flex-end;
      &:hover {
        background-color: #222;
        border-left: 0;
      }

      .toggle {
        display: flex;
        align-items: center;
        .list-item-name {
          min-width: 0;
        }
      }
    }

    .list-item-name {
      min-width: 200px;
      display: flex;
      align-items: center;
      font-weight: 200;
      cursor: pointer;
      .module-icon {
        margin-right: 7px;
      }
      .module-name {
        margin-right: 7px;
      }
      .module-type {
        color: $label-gray-dark;
        margin-right: 7px;
      }
    }

    &.screen {
      padding-left: 40px;
    }

    border-left: 2px solid $modules-bg-gray;
    &:hover {
      background-color: #444;
      border-left: 2px;
      border-style: solid;
      border-image: linear-gradient(to bottom, $highlight, yellow) 1 100%;
    }
    &.module:hover {
      background-color: #444;
      border-left: 2px;
      border-style: solid;
      border-image: linear-gradient(to bottom, red, orange) 1 100%;
    }

    .module-options {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .module-option {
        background-color: $modules-bg-gray;
        margin: 5px;
        padding: 5px;
        display: flex;
        align-items: center;
        border: 1px solid $white;
        width: 390px;
        cursor: pointer;
        &:hover {
          background: #444;
        }
        .module-image {
          margin-right: 10px;
          width: 50px;
          height: 50px;
          background: #000;
          img {
          }
        }
      }
    }

    .list-item-actions {
      display: flex;
      .action {
        margin-left: 5px;
        cursor: pointer;
      }
    }

    &.add-new-screen {
      min-height: 0;
      border-left: none;
      cursor: pointer;
      &:hover {
        border-left: none;
      }
    }
    &.select-module {
      background-color: #222;
      border-left: none;
      &:hover {
        border-left: none;
        background-color: #222;
      }
    }
  }
}

.MuiDialog-root {
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .MuiBreadcrumbs-root {
    li,
    p {
      cursor: pointer;
      color: $label-gray-dark;
    }
  }
  .MuiDialogTitle-root {
    padding: 15px;
    height: 50px;
  }
  .MuiDialogContent-root {
    color: $white;
    background-color: $black;
    padding: 1em;

    .edit-option-container {
      margin: 10px auto;
      .edit-module-option {
        padding: 10px 10px 10px 10px;
      }
    }

    .transfer-list-container {
      width: 100%;

      .MuiGrid-root {
        width: 100%;
        height: 300px;
        padding: 10px 10px 10px 10px;
        margin: 10px auto;

        .MuiPaper-root {
          overflow: auto;
          height: 100%;
          color: $black;
        }

        .MuiGrid-item {
          width: 25%;
          height: 100%;
        }

        .transfer-button-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          outline: none;
          .transfer-buttons {
            height: auto;
            button {
              margin: 5px auto;
              border: 1px solid $border-color;
            }
          }
        }
        .MuiListItemIcon-root {
          .MuiIconButton-label {
            color: $white;
          }
        }
      }
    }

    .dialog-action-button-container {
      position: absolute;
      right: 5px;
      bottom: 10px;
      width: 100%;

      .add-module {
        border: none;
        float: left;
        display: flex;
        width: auto;
        cursor: pointer;
        margin-left: 2%;

        svg {
          padding-left: 10px;
        }
      }

      .actions {
        margin-right: 1%;
        float: right;
      }
    }

    .MuiTable-root {
      .MuiTableBody-root {
        .MuiTableRow-root {
          .check_box_cell {
            text-align: center;
          }
          .MuiTableCell-root {
            padding: 2px 10px 2px 10px;
            font-size: 20px;
          }
        }
      }
    }
    .MuiTab-root {
      margin: 1px auto;
      border: 1px solid $border-color;
      background-color: $modules-bg-gray;

      .selected {
        background-color: $label-gray;
      }
    }
  }
  .MuiDialog-paper {
    border: 2px solid $white;
  }
  .dialog-close-button {
    position: absolute;
    right: 0;
    top: 0;
    color: $white;
  }
  .MuiDialogActions-root {
    border: 1px solid $border-color;
    background-color: $black;
  }
}

// BEGIN (╯°□°)╯︵ ┻━┻

.MuiTable-root {
  .MuiTableHead-root {
    .MuiTableRow-root {
      .MuiTableCell-root {
        text-align: left;
        background-color: $black;
        border: 1px solid $border-color;
        color: $white;
        cursor: pointer;
      }
    }
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      .MuiTableCell-root {
        border: 1px solid $border-color;
        color: $white;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100px;
        cursor: pointer;
      }
    }
  }
}

.MuiTableCell-head {
  background: #111 !important;
  border-top: 1px solid #000 !important;
  border-left: 1px solid #000 !important;
  border-right: 1px solid #000 !important;
}

.MuiTablePagination-root {
  position: relative;
  bottom: 0;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  display: flex;
  background-color: $black;
}

.MuiTableSortLabel-active {
  svg {
    width: 18px;
  }
}

// END ┬─┬ノ( º _ ºノ)

// No rounded edges!
.MuiPaper-rounded {
  border-radius: 0;
}

.MuiFormHelperText-root {
  color: #aaa;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}
