@import 'src/themes/theme';

.page-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 25px;
    background: rgba(99, 99, 99, 0.1);
    padding: 25px;
    border-radius: 5px;

    .MuiCircularProgress-root {
      color: #0cbf0c;
      width: 25px !important;
      height: 25px !important;
    }

    > span {
      color: rgba(255, 255, 255, 0.48);
      font-size: 20px;
    }
  }
}
.cards-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10vh;

  a {
    text-decoration: none;
  }

  .home-card {
    margin: 15px 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #3c3c3c;
    * {
      color: $white;
    }
    width: 400px;
    height: 80px;
    border: 1px solid $white;
    border-radius: 6px;

    @media screen and (max-width: 444px) {
      width: 350px;
      margin: 10px 0;
    }

    -webkit-box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.25);

    .visual-area {
      width: 80px;
      background-color: #111;
      border-radius: 6px 0 0 6px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title-area {
      padding: 2px;
      flex-grow: 1;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      grid-gap: 5px;

      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        // height: 40px;
        h1 {
          font-size: 24px;
        }
        @media screen and (max-width: 444px) {
          h1 {
            font-size: 20px;
          }
        }
      }
      .subtitle {
        h1 {
          font-size: 18px;
        }
      }
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  padding: 15px;
  font-size: 14px;
  align-items: flex-end;

  .version {
    opacity: .3;
  }

  button {
    color: white;
    background: transparent;
    text-decoration: underline;
    border: none;
    cursor: pointer;
    font-size: 14px;
  }

  .right-content {
    display: flex;
    align-items: flex-end;


    .branding-logo {
      margin-right: 10px;
      img {
        height: 40px;
      }
    }
  }
}
