$theme: standard;

$black: #000 !default;
$white: #fff !default;
$gray: #444 !default;
$modules-bg-gray: #333 !default;
$surround-color: $black !default;
$border-color: rgba(#444, 1) !default;
$loader-color: #fff !default;
$inactive: #777 !default;
$label-gray: #ccc !default;
$label-gray-dark: #aaa !default;
$system-font: 'Roboto', sans-serif !default;
$gauge-label-font: 'Roboto Condensed', sans-serif !default;
$gauge-value-font: 'Varela Round', sans-serif !default;
$warning-text-color: #ff0000 !default;
$transparent: #ffffff00 !default;
$compliance: #c65900 !default;
$blood-product-red: #6b0000 !default;
$fluid-blue: #2dc7dc !default;
$drug-gray: #999 !default;
$gas-green: #63de00 !default;
$divider: #252525 !default;

// Buttons
$button-default-color: #333;
$button-default-hover-color: #444;

// Colors
$primary: #bbf09a;
$primary-light: #eeffcc;
$primary-dark: #8abd6b;
$highlight: #63de00 !default;
$secondary: #ffab8d;
$secondary-light: #ffddbd;
$secondary-dark: #c97b5f;
$secondary-highlight: #de3f00;
$primary-variant: #1faa00; // LARGE text only if using white on top of this
$label: rgba($white, 0.54) !default;
$success: #bbf09a;
$info: #90c9f9;
$error: #cf6679 !default;
$warning: #ffca7f !default;
$disabled: rgba($white, 0.33) !default;

@if $theme == highcontrast {
  $border-color: rgba(#fff, 1);
}

@if $theme == lightmode {
  $black: #fff;
  $white: #000;
  $gray: #ccc;
  $modules-bg-gray: #bbb;
  $surround-color: $black;
  $label-gray: #777;
}

// Export themes for use in JS files
:export {
  black: $black;
  gray: $gray;
  error: $error;
  success: $success;
  warning: $warning;
  labelGrayDark: $label-gray-dark;
  compliance: $compliance;
}
